import { Button, Card, CardHeader, CardBody, CardFooter, Container, Box, Flex, Grid, Heading, Image, Stack, StackDivider, TableContainer, Table, Thead, Tbody, Td, Tr, Th, Text } from "@chakra-ui/react"
import { Link } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

function Intro() {
  return <Container maxW='container.xl' id="info-rsvp">
  <Grid templateColumns={{sm:'repeat(1, 1fr)', md:'repeat(2, 1fr)'}} gap={12} alignItems="center">
    <div className = 'rsvp'>
      <Heading fontWeight='500' fontStyle='italic' size='xl'>Join us as we celebrate our love and begin our journey together. We sincerely hope you can attend!</Heading>
      <Link href="/rsvp">
        <Button size='lg' marginTop='1em'>
          Click to RSVP
        </Button>
      </Link>
    </div>
    <Card className='cardOverride'>
        <CardBody>
          <Stack divider={<StackDivider className='dividerOverride' />} spacing='4'>
            <Box>
              <Text fontSize='sm' fontWeight='800' textTransform='uppercase'>
                When
              </Text>
              <Text pt='2' fontSize='md' fontWeight='500'>
                Saturday, November 2, 2024
              </Text>
            </Box>
            <Box>
              <Text fontSize='sm' fontWeight='800' textTransform='uppercase'>
                Where
              </Text>
              <Text pt='2' fontSize='md' fontWeight='500'>
                Knoxville Ranch<br />
                West, Texas
              </Text>
            </Box>
            <Box>
              <Text fontSize='sm' fontWeight='800' textTransform='uppercase'>
                Travel Information
              </Text>
              <Text pt='2' fontSize='md' fontWeight='500'>
                <TableContainer outline='1px solid black'>
                  <Table size='sm' colorScheme='black'>
                    <Thead>
                      <Tr>
                        <Th>Airport</Th>
                        <Th>Distance</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>Dallas Fort Worth International Airport (DFW)</Td>
                        <Td>102 miles</Td>
                      </Tr>
                      <Tr>
                        <Td>Austin-Bergstrom International Airport (AUS)</Td>
                        <Td>124 miles</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </Text>
            </Box>
            <Box>
              <Text fontSize='sm' fontWeight='800' textTransform='uppercase'>
                Dress Code
              </Text>
              <Text pt='2' fontSize='md' fontWeight='500'>
                <b>Welcome Party:</b> Indo-Western, Traditional Indian, Cocktail Attire or Business Casual
              </Text>
              <Text pt='2' fontSize='md' fontWeight='500'>
                <b>Wedding:</b> Traditional Indian or Church Attire, No Black Clothing
              </Text>
              <Text pt='2' fontSize='md' fontWeight='500'>
                <b>Reception:</b> Cocktail Attire, Formal, Traditional Indian or Indo-Western
              </Text>
            </Box>
          </Stack>
        </CardBody>
      </Card>
    </Grid>
  </Container>
}

export default Intro;