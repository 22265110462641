import { Box, Button, Center, Container, Flex, Image, Input, Link, Text } from "@chakra-ui/react"
import { Field, Form, Formik } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react'

import { useEffect } from 'react'

export default function NameCollection({Field, errors, touched, ErrorMessage, resetForm}) {
  useEffect(() => {
    window.scrollTo(0, 0)
    resetForm()
  }, []);

  return (
    <Box>
      <FormControl marginBottom='1em'>
        <FormLabel>First Name</FormLabel>
        <Field 
          type="text" 
          name='name'
        >
          {({ field, form }) => (
            <Input autoFocus {...field} placeholder='e.g. Amitabh' />
          )}
        </Field>
      </FormControl>
    </Box>
  )
}