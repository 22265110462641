import { Box, Center, Image, Link, Text } from "@chakra-ui/react"

import { Component, useEffect } from 'react'

function sortRsvpNames(originalList, names) {
  return names.sort((a, b) => originalList.indexOf(a) - originalList.indexOf(b))
}

function formatRsvpNames(originalList, names) {
  if (!names || !names.length) {
    return 'No one is attending'
  }
  const sortedNames = sortRsvpNames(originalList, names)
  return sortedNames.length > 1
    ? sortedNames.slice(0, -1).join(', ') + ' and ' + sortedNames.slice(-1) + ' are attending'
    : sortedNames[0] + ' is attending'
}

function formatAcceptedEvents(events) {
  return (events && events.length)
    ? (events.length > 1
      ? events.slice(0, -1).join(', ') + ' and ' + events.slice(-1)
      : events[0])
    : ''
}

function formatHotelName(hotel) {
  if (hotel === 'La Quinta - Woodway') {
    return 'La Quinta Inn & Suites - Waco South'
  } else if (hotel === 'La Quinta - Downtown') {
    return 'La Quinta Inn and Suites - Waco Downtown'
  } else if (hotel === 'Holiday Inn') {
    return 'Holiday Inn & Suites Waco Northwest'
  } else if (hotel === 'Best Western') {
    return 'Best Western Plus Waco North'
  }
}

function formatHotelLink(hotel) {
  if (hotel === 'La Quinta - Woodway') {
    return 'https://www.wyndhamhotels.com/laquinta/woodway-texas/la-quinta-woodway-waco-south/overview?checkInDate=10/30/2024&checkOutDate=11/04/2024&groupCode=2411TAWED'
  } else if (hotel === 'La Quinta - Downtown') {
    return 'https://www.wyndhamhotels.com/laquinta/waco-texas/la-quinta-inn-and-suites-waco-downtown-baylor/overview?checkInDate=10/31/2024&checkOutDate=11/03/2024&groupCode=TJAW'
  } else if (hotel === 'Holiday Inn') {
    return 'https://www.holidayinn.com/redirect?path=rates&brandCode=HI&localeCode=en&regionCode=1&hotelCode=ACTUP&checkInDate=30&checkInMonthYear=092024&checkOutDate=04&checkOutMonthYear=102024&_PMID=99801505&GPC=RAW&cn=no&viewfullsite=true'
  } else if (hotel === 'Best Western') {
    return 'https://www.bestwestern.com/en_US/book/hotel-rooms.44676.html?groupId=2J0IF9Q5'
  }
}

function arrayEquals(a, b) {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}

export default function SaveRsvp({values, goto, dirty, touched}) {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, []);

    const guestDetails = JSON.parse(values.guestDetails)

    const side = guestDetails['side']
    const rowId = guestDetails['rowId'] - 2

    const guestNames = guestDetails['Website Names']
    const hotel = guestDetails['Hotel'] && guestDetails['Hotel'].trim()
    const hotelName = formatHotelName(hotel)
    const hotelLink = formatHotelLink(hotel)
    
    const vidhiInvited = guestDetails["Vidhi Invited"] > 0 ? parseInt(guestDetails["Vidhi Invited"]) : undefined
    const welcomePartyInvited = guestDetails["Welcome Party Invited"] > 0 ? parseInt(guestDetails["Welcome Party Invited"]) : undefined
    const weddingInvited = guestDetails["Wedding Invited"] > 0 ? parseInt(guestDetails["Wedding Invited"]) : undefined
    const receptionInvited = guestDetails["Reception Invited"] > 0 ? parseInt(guestDetails["Reception Invited"]) : undefined

    const previousVidhiRsvped = guestDetails["Vidhi RSVP Names"]
      ? guestDetails["Vidhi RSVP Names"].split(',').map(s => s.trim())
      : []
    const vidhiRsvpCount = values.vidhiRsvp ? values.vidhiRsvp.length : 0
    const vidhiRsvpNames = values.vidhiRsvp ? sortRsvpNames(guestNames, values.vidhiRsvp) : []

    const previousWelcomePartyRsvped = guestDetails["Welcome Party RSVP Names"]
      ? guestDetails["Welcome Party RSVP Names"].split(',').map(s => s.trim())
      : []
    const welcomePartyRsvpCount = values.welcomePartyRsvp ? values.welcomePartyRsvp.length : 0
    const welcomePartyRsvpNames = values.welcomePartyRsvp ? sortRsvpNames(guestNames, values.welcomePartyRsvp) : []
    
    const previousWeddingRsvped = guestDetails["Wedding RSVP Names"]
      ? guestDetails["Wedding RSVP Names"].split(',').map(s => s.trim())
      : []
    const weddingRsvpCount = values.weddingRsvp ? values.weddingRsvp.length : 0
    const weddingRsvpNames = values.weddingRsvp ? sortRsvpNames(guestNames, values.weddingRsvp) : []
   
    const previousReceptionRsvped = guestDetails["Reception RSVP Names"]
      ? guestDetails["Reception RSVP Names"].split(',').map(s => s.trim())
      : []
    const receptionRsvpCount = values.receptionRsvp ? values.receptionRsvp.length : 0
    const receptionRsvpNames = values.receptionRsvp ? sortRsvpNames(guestNames, values.receptionRsvp) : []
    

    const previousEmail = guestDetails["Email"]
    const email = values.email
    const previousPhone = guestDetails["Phone Number"]
    const phone = values.phone

    const acceptedEvents = []
    if (vidhiRsvpCount > 0) {
      acceptedEvents.push('Vidhi');
    }
    if (welcomePartyRsvpCount > 0) {
      acceptedEvents.push('Welcome Party');
    }
    if (weddingRsvpCount > 0) {
      acceptedEvents.push('Wedding');
    }
    if (receptionRsvpCount > 0) {
      acceptedEvents.push('Reception');
    }

    const formattedAcceptedEvents = formatAcceptedEvents(acceptedEvents)
    const formattedVidhiNames = formatRsvpNames(guestNames, values.vidhiRsvp)
    const formattedWelcomePartyNames = formatRsvpNames(guestNames, values.welcomePartyRsvp)
    const formattedWeddingNames = formatRsvpNames(guestNames, values.weddingRsvp)
    const formattedReceptionNames = formatRsvpNames(guestNames, values.receptionRsvp)

    if (!arrayEquals(previousVidhiRsvped, vidhiRsvpNames)
      || !arrayEquals(previousWelcomePartyRsvped, welcomePartyRsvpNames)
      || !arrayEquals(previousWeddingRsvped, weddingRsvpNames)
      || !arrayEquals(previousReceptionRsvped, receptionRsvpNames)
      || previousEmail !== email
      || previousPhone !== phone) {
      const sheetUrl = side === 'Anish'
        ? 'https://sheet.best/api/sheets/34ff4c24-6d92-4538-b519-4d82eb628fef/tabs/Guest%20List%20-%20Groom'
        : 'https://sheet.best/api/sheets/34ff4c24-6d92-4538-b519-4d82eb628fef/tabs/Guest%20List%20-%20Bride'
      fetch(`${sheetUrl}/` + rowId, {
        method: 'PATCH',
        body: JSON.stringify({
          'Vidhi RSVP Count': vidhiRsvpCount,
          'Vidhi RSVP Names': vidhiRsvpNames.join(', '),
          'Welcome Party RSVP Count': welcomePartyRsvpCount ? welcomePartyRsvpCount : '\'0',
          'Welcome Party RSVP Names': welcomePartyRsvpNames.join(', '),
          'Wedding RSVP Count': weddingRsvpCount ? weddingRsvpCount : '\'0',
          'Wedding RSVP Names': weddingRsvpNames.join(', '),
          'Reception RSVP Count': receptionRsvpCount ? receptionRsvpCount : '\'0',
          'Reception RSVP Names': receptionRsvpNames.join(', '),
          'Email': email,
          'Phone Number': phone
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      })
      .then((response) => response.json())
      .then((json) => console.log(json))
      .catch(err => {
        console.log(err);
      });

      fetch(`https://tejalanish-wedding-site-api.vercel.app/api/emailRsvpConfirmation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }, 
        body: JSON.stringify({
          "email": email,
          "dynamicTemplateData": {
            "name": guestNames,
            "side": side,
            "vidhiInvited": vidhiInvited,
            "formattedVidhiNames": formattedVidhiNames,
            "welcomePartyInvited": welcomePartyInvited,
            "formattedWelcomePartyNames": formattedWelcomePartyNames,
            "weddingInvited": weddingInvited,
            "formattedWeddingNames": formattedWeddingNames,
            "receptionInvited": receptionInvited,
            "formattedReceptionNames": formattedReceptionNames,
            "acceptedEvents": formattedAcceptedEvents,
            "hotel": hotel,
            "hotelName": hotelName,
            "hotelLink": hotelLink
          }
        })
      })
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          console.error(error)
        })
    }

    return (
      <Box className='reviewRsvp'>
        <Box>
          <Text fontSize='md' fontWeight='500'>Contact Information</Text> 
          <div>Email Address: {email}</div>
          <div>Phone Number: {phone}</div>
        </Box>
        <Box>
          <Text fontSize='md' fontWeight='500'>RSVP Details</Text> 
          {vidhiInvited && <div>Manglik Prasango: {formattedVidhiNames}</div>}
          {welcomePartyInvited && <div>Welcome Party: {formattedWelcomePartyNames}</div>}
          {weddingInvited && <div>Wedding: {formattedWeddingNames}</div>}
          {receptionInvited && <div>Reception: {formattedReceptionNames}</div>}
        </Box>
        {hotel && hotel.trim() && (
          <Box>
            <Text fontSize='md' fontWeight='500'>Hotel Booking Information</Text> 
            A room block has been reserved at the {hotelName}. Please <Link color='messenger.500' isExternal={true} href={hotelLink}>click here</Link> to book your room.
          </Box>
        )}
      </Box>
    )
}
