import { AspectRatio, Button, Card, CardHeader, CardBody, CardFooter, Center, Container, Box, Flex, Grid, Heading, Image, Stack, StackDivider, Text } from "@chakra-ui/react"

function Footer() {
  return <Container maxW='container.xl' paddingBottom='2.5em'>
    <Center flexDirection='column'>
      <Image
        src='/images/ta-logo.png'
        width='3.25em'
      />
      <Text pt='2' fontSize='md' fontWeight='500'>With <Image src='/images/emoji/2665.png' display='inline-block' height='1em' verticalAlign='-0.2em' /> from the Bride & Groom</Text>
    </Center>
  </Container>;
}

export default Footer;