import { Box, Button, Center, Container, Flex, Image, Input, Link, Text } from "@chakra-ui/react"
import { Field, Form, Formik } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react'

import React, { Component, useEffect } from "react";

export default class CollectContact extends Component {
  constructor(props){ 
    super(props)

    this.state = {
      setRsvpData: false
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    const setFieldValue = this.props.setFieldValue
    const formValues = this.props.values

    if (!this.state.setRsvpData) {
      const email = formValues.email
      const phone = formValues.phone

      const guestDetails = JSON.parse(formValues.guestDetails)
      
      const submittedEmail = guestDetails["Email"] || ''
      const submittedPhone = guestDetails["Phone Number"] || ''

      if (!email) {
        setFieldValue('email', submittedEmail)
      }

      if (!phone) {
        setFieldValue('phone', submittedPhone)
      }

      this.setState({
        setRsvpData: true,
      })
    }
  }

  componentDidUpdate() {
    const setFieldValue = this.props.setFieldValue
    const formValues = this.props.values

    if (!this.state.setRsvpData) {
      const email = formValues.email
      const phone = formValues.phone

      const guestDetails = JSON.parse(formValues.guestDetails)
      
      const submittedEmail = guestDetails["Email"] || ''
      const submittedPhone = guestDetails["Phone Number"] || ''

      if (!email) {
        setFieldValue('email', submittedEmail)
      }

      if (!phone) {
        setFieldValue('phone', submittedPhone)
      }

      this.setState({
        setRsvpData: true,
      })
    }
  }
  render () {
    return (
      <Box>
        <FormControl className='rsvpSelect'>
          <FormLabel>Email Address</FormLabel>
            <Field 
              type="text" 
              name='email'
            >
              {({ field, form }) => (
                <Input {...field} placeholder='amitabh@gmail.com' />
              )}
            </Field>
          </FormControl>
        <FormControl className='rsvpSelect'>
          <FormLabel>Phone Number</FormLabel>
          <Field 
            type="text" 
            name='phone'
          >
            {({ field, form }) => (
              <Input {...field} placeholder='123-456-7890' />
            )}
          </Field>
        </FormControl>
      </Box>
    )
  }
}