import { Box, Button, Center, Container, Heading, Input, Link, Text } from "@chakra-ui/react";
import React, { useRef } from "react";

import RsvpStepper from '../components/RsvpStepper.js';

import NameCollection from '../components/NameCollection.js';
import FindGuest from '../components/FindGuest.js';
import CollectContact from '../components/CollectContact.js';
import CollectRsvp from '../components/CollectRsvp.js';
import SaveRsvp from '../components/SaveRsvp.js';

import { Formik, Field, Form, ErrorMessage } from 'formik'; 
import * as Yup from 'yup'

function validateField(step, values) {
  if (step === 0) {
    return values['name'].trim().length >= 3
  } else if (step === 1) {
    return values['guestDetails']
  } else if (step == 2) {
    return values['email'].trim() && values['phone'].trim()
  }
  return true;
}

export default function RsvpForm({step, next, prev, goto}) {
  return <Formik
    initialValues={{
      name: '',
      guestDetails: '',
      vidhiRsvp: [],
      welcomePartyRsvp: [],
      weddingRsvp: [],
      receptionRsvp: [],
      phone: '',
      email: ''
    }}

    onSubmit={ (values, {resetForm}) => {      
      if (step !== 4) {
      	return next()
      }
    }}
  >
    {({values, errors, touched, isValid, dirty, setFieldValue, resetForm}) => (
      <Box>
        <Box marginTop='1em'>
          <RsvpStepper formStep={step} />
        </Box>
        <Box>
          <Form className='rsvpForm'>
            {step === 0 && <NameCollection 
              Field={Field} 
              errors={errors} 
              touched={touched} 
              ErrorMessage={ErrorMessage}
              resetForm={resetForm} />}

            {step === 1 && <FindGuest 
              Field={Field} 
              values={values}
              errors={errors} 
              touched={touched} 
              ErrorMessage={ErrorMessage} />}

            {step === 2 && <CollectContact 
              Field={Field} 
              values={values}
              errors={errors} 
              touched={touched} 
              ErrorMessage={ErrorMessage}
              setFieldValue={setFieldValue} />}

            {step === 3 && <CollectRsvp 
              Field={Field} 
              values={values}
              errors={errors} 
              touched={touched} 
              ErrorMessage={ErrorMessage}
              setFieldValue={setFieldValue} />}

            {step === 4 && <SaveRsvp 
              values={values}
              goto={goto} />}

            {step !== 0 && <Button onClick={prev} colorScheme={'gray'}>{step === 4 ? 'Change RSVP' : 'Back'}</Button>}

            {step === 0 && <div></div>}

            { step !== 4 &&
              <Button
                type="submit"
                isDisabled={!validateField(step, values)} 
                colorScheme={step === 3 ? 'messenger' : 'gray'}>
                {step === 3 ? 'Confirm' : 'Next' }
              </Button> }
          </Form>
        </Box>
      </Box>
    )}
  </Formik>
}
