import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';

import Homepage from './pages/Homepage';
import RSVP from './pages/RSVP';

const Main = () => {
  return (
    <Routes>
      <Route path='/' element={<Homepage />}></Route>
      <Route path='/rsvp' element={<RSVP />}></Route>
      <Route
        path="*"
        element={<Navigate to="/" replace={true} />}
      />
    </Routes>
  );
}

export default Main;