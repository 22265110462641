import { Box, Image, Show, Stepper, Step, StepIcon, StepNumber, StepIndicator, StepTitle, StepDescription, StepStatus, StepSeparator, Text } from "@chakra-ui/react"

const steps = [
  { title: 'Find RSVP' },
  { title: 'Contact Info' },
  { title: 'RSVP' },
]

function RsvpStepper({formStep}) {
  const stepperStep = formStep === 0 ? 0 : formStep - 1
  return (
    <Box>
      <Show above='sm'>
        <Stepper index={stepperStep} marginBottom='1.5em'>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink='0'>
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </Box>

              <StepSeparator />
            </Step>
          ))}
        </Stepper>
        {stepperStep === steps.length && (
          <Box display='flex' alignItems='center' gap='.25em' marginTop='1em' marginBottom='1em'>
            <Text display='inline-block' fontSize='md' fontWeight='500'>Thank you for RSVPing!</Text>
            <Image display="inline-block" src='/images/emoji/1f973.png' height='1.5em' />
          </Box>
        )}
      </Show>
      <Show below='sm'>
        <Stepper size='sm' index={stepperStep} gap='0'>
          {steps.map((step, index) => (
            <Step key={index} gap='0'>
              <StepIndicator>
                <StepStatus complete={<StepIcon />} />
              </StepIndicator>
              <StepSeparator _horizontal={{ ml: '0' }} />
            </Step>
          ))}
        </Stepper>
        <Text textAlign='center' marginTop='0.5em' fontWeight='500' marginBottom='1.5em'>
          {stepperStep < steps.length
            ? `Step ${stepperStep + 1}: ${steps[stepperStep].title}`
            : <Box display='flex' alignItems='center' justifyContent='center' gap='.25em'>
              <Text display='inline-block' fontSize='md' fontWeight='500'>Thank you for RSVPing!</Text>
              <Image display="inline-block" src='/images/emoji/1f973.png' height='1.5em' />
            </Box>
          }
        </Text>
      </Show>
    </Box>
  )
}

export default RsvpStepper;