import { Image, Flex, Button, HStack, chakra } from '@chakra-ui/react';
import React from "react";
import { Link } from "react-scroll";
import useSticky from './UseSticky';
import classNames from 'classnames/bind';

export default function DesktopNav() {
  const { sticky, stickyRef } = useSticky();
  return (
    <chakra.header ref={stickyRef} className={classNames('nav', { sticky })}>
      <Flex
        w="100%"
        px="6"
        py="5"
        align="center"
        justify="space-between"
        display={{ base: "none", md: "flex" }}
        className='desktopHeader'
      >        
        <HStack as="nav" spacing="5">
          <Link activeClass="active" smooth spy to='info-rsvp'>
            <Button variant="nav">Info & RSVP</Button>
          </Link>
        </HStack>
        <HStack as="nav" spacing="5">
          <Link activeClass="active" smooth spy to='timeline'>
            <Button variant="nav">Timeline</Button>
          </Link>
        </HStack>
        <HStack as="nav" spacing="5">
          <Link cactiveClass="active" smooth spy to='proposal'>
            <Button variant="nav">Proposal</Button>
          </Link>
        </HStack>
        <HStack as="nav" spacing="5">
          <Link activeClass="active" smooth spy to='wedding-party'>
            <Button variant="nav">Wedding Party</Button>
          </Link>
        </HStack>
        <HStack as="nav" spacing="5">
          <Link activeClass="active" smooth spy to='photo-reel'>
            <Button variant="nav">Photo Reel</Button>
          </Link>
        </HStack>
      </Flex>
    </chakra.header>
  );
}