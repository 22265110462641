import { Box, Button, Center, Container, Flex, Image, Input, Link, Radio, RadioGroup, Spinner, Stack, Text } from "@chakra-ui/react"
import { Field, Form, Formik } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react'

import React, { Component } from "react";

import Fuse from "fuse.js";

export default class FindGuest extends Component {
  constructor(props){ 
    super(props) 

    this.state = {
      anishGuestList: [],
      tejalGuestList: []
    } 
  } 

  componentDidMount() {
    window.scrollTo(0, 0)
    
    const input = this.props.values.name.trim()

    Promise.all([
      fetch('https://sheet.best/api/sheets/34ff4c24-6d92-4538-b519-4d82eb628fef/tabs/Guest%20List%20-%20Groom'),
      fetch('https://sheet.best/api/sheets/34ff4c24-6d92-4538-b519-4d82eb628fef/tabs/Guest%20List%20-%20Bride'),
    ])
      .then(([rawAnishGuestList, rawTejalGuestList]) => 
        Promise.all([rawAnishGuestList.json(), rawTejalGuestList.json()])
      )
      .then(([anishGuestList, tejalGuestList]) => {
        anishGuestList.forEach((guest, i) => {
          guest['side'] = 'Anish'
          guest['rowId'] = i + 2
        });
        tejalGuestList.forEach((guest, i) => {
          guest['side'] = 'Tejal'
          guest['rowId'] = i + 2
        });

        const fuse = new Fuse([...anishGuestList, ...tejalGuestList], {
          keys: ["Website Names"],
          threshold: 0.2,
          ignoreLocation: true,
          findAllMatches: true,
        });
        const searchResults = fuse.search(input)
        this.setState({searchResults: searchResults})
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const input = this.props.values.name

    return (
      <Box>
        {(!this.state.searchResults)
          ? <Center>
              <Spinner size='lg'/>
            </Center>
          :  !this.state.searchResults.length
            ? `Sorry we could not find a party containing "${input}". Please go back to try again or email tnp2988@gmail.com if you continue to be unsuccessful.`
            : <FormControl>
                <FormLabel>Select Your Party</FormLabel>
                <Field type="text" name='guestDetails'>
                  {({ field, form }) => (
                    <RadioGroup {...field}>
                      {this.state.searchResults.map((d) => {
                        return <div>
                          <Radio {...field} value={JSON.stringify(d.item)}>{d['item']['Website Names']}<Text fontSize='sm' className='helperText'>{d.item.side}'s Side</Text></Radio>
                        </div>
                      })}
                    </RadioGroup>
                  )}
                </Field>
              </FormControl>
      }
      </Box>
    )
  }
}