import { useDisclosure, Flex, Box, Button,  VStack, Icon, HStack, Link as ChakraLink } from "@chakra-ui/react";
import MenuDrawer from './MenuDrawer.js';
import { HamburgerIcon } from '@chakra-ui/icons'
import { Link } from 'react-scroll';
import React from "react";

export default function MobileDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  return (
    <Box className='mobileDrawer' display={{ base: "block", md: "none" }}>
      <Button ref={btnRef} onClick={onOpen} backgroundColor='unset' _hover={{ backgroundColor: 'unset'}}>
        <HamburgerIcon boxSize="1.75em" color='white' />
      </Button>
      
      <MenuDrawer
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={btnRef}
        backgroundColor={'snow'}
      >
        <VStack alignItems="left">
          <Link activeClass="active" smooth spy to='info-rsvp'>
            <Button variant="nav">Info & RSVP</Button>
          </Link>
          <Link activeClass="active" smooth spy to='timeline'>
            <Button variant="nav">Timeline</Button>
          </Link>
          <Link cactiveClass="active" smooth spy to='proposal'>
            <Button variant="nav">Proposal</Button>
          </Link>
          <Link activeClass="active" smooth spy to='wedding-party'>
            <Button variant="nav">Wedding Party</Button>
          </Link>
          <Link activeClass="active" smooth spy to='photo-reel'>
            <Button variant="nav">Photo Reel</Button>
          </Link>
        </VStack>
      </MenuDrawer>
    </Box>
  );
};