import { extendTheme,withDefaultColorScheme } from '@chakra-ui/react'

export default extendTheme({
  fonts: {
    heading: `'legitima', serif`,
    body: `'SF Pro', sans-serif`,
  },
  styles: {
    global: (props) => ({
      body: {
        bg: '#fffafa',
      },
    })
  },
},
withDefaultColorScheme({ colorScheme: 'messenger' }))
