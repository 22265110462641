import { Box, Flex } from "@chakra-ui/react"
import { Text } from "@chakra-ui/react"

function Banner() {
  return <Box className='banner' display="flex" alignItems="center" justifyContent="center" flexDirection='column'>
    <Text fontSize={{ base: '1em', sm: '1.1em', md: '1.25em' }} letterSpacing='.5em' fontWeight='500'>THE WEDDING OF</Text>
    <Text fontFamily='legitima' fontSize={{ base: '4em', sm: '4em', md: '6em', lg: '6.5em' }} lineHeight='1.1' letterSpacing='.1em' fontWeight='500' fontStyle='italic'>TEJAL & ANISH</Text>
    <div class='icon-scroll' />
  </Box>;
}

export default Banner;