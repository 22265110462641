import Banner from '../components/Banner.js';
import Intro from '../components/Intro.js';
import Timeline from '../components/Timeline.js';
import Proposal from '../components/Proposal.js';
import WeddingParty from '../components/WeddingParty.js';
import ScrollingImages from '../components/ScrollingImages.js';
import DesktopNav from '../components/DesktopNav.js';
import MobileDrawer from '../components/MobileDrawer.js';
import Footer from '../components/Footer.js';

import { Box } from '@chakra-ui/react';

function Homepage() {
  return (
		<Box className='homepage'>
			<Banner />
			<DesktopNav />
			<MobileDrawer />
			<Box className='sections'>
			  <Intro />
			  <Timeline />
			  <WeddingParty />
			  <ScrollingImages />
			  <Footer />
			</Box>
		</Box>
  )
}

export default Homepage