import { AspectRatio, Button, Card, CardHeader, CardBody, CardFooter, Center, Container, Box, Flex, Grid, Heading, Image, Stack, StackDivider, Text } from "@chakra-ui/react"

function ScrollingImages() {
  return <Container maxW='100%' paddingLeft={0} paddingRight={0} id="photo-reel">
    <Center>
      <Heading fontWeight='500' size='xl' display="flex" gap='.25em'>
        <span>Photo Reel <Image src='/images/emoji/1f4f8.png' display='inline-block' height='1.1em' verticalAlign='-0.25em' /></span>
      </Heading>
    </Center>
    <div class="marquee">
      <div class="marquee-content">
        <div class="marquee-tag">
          <Image
            src='/images/reel/1.jpeg'
            borderRadius='lg'
            height='16em'
            width='16em'
            objectFit='cover'
          />
        </div>
        <div class="marquee-tag">
          <Image
            src='/images/reel/2.jpeg'
            borderRadius='lg'
            height='16em'
            width='16em'
            objectFit='cover'
          />
        </div>
        <div class="marquee-tag">
          <Image
            src='/images/reel/3.jpeg'
            borderRadius='lg'
            height='16em'
            width='16em'
            objectFit='cover'
          />
        </div>
        <div class="marquee-tag">
          <Image
            src='/images/reel/4.jpeg'
            borderRadius='lg'
            height='16em'
            width='16em'
            objectFit='cover'
          />
        </div>
        <div class="marquee-tag">
          <Image
            src='/images/reel/5.jpeg'
            borderRadius='lg'
            height='16em'
            width='16em'
            objectFit='cover'
          />
        </div>
        <div class="marquee-tag">
          <Image
            src='/images/reel/6.jpeg'
            borderRadius='lg'
            height='16em'
            width='16em'
            objectFit='cover'
          />
        </div>
        <div class="marquee-tag">
          <Image
            src='/images/reel/7.jpeg'
            borderRadius='lg'
            height='16em'
            width='16em'
            objectFit='cover'
          />
        </div>
        <div class="marquee-tag">
          <Image
            src='/images/reel/8.jpeg'
            borderRadius='lg'
            height='16em'
            width='16em'
            objectFit='cover'
          />
        </div>
        <div class="marquee-tag">
          <Image
            src='/images/reel/9.jpeg'
            borderRadius='lg'
            height='16em'
            width='16em'
            objectFit='cover'
          />
        </div>
      </div>
      <div class="marquee-content">
        <div class="marquee-tag">
          <Image
            src='/images/reel/1.jpeg'
            borderRadius='lg'
            height='16em'
            width='16em'
            objectFit='cover'
          />
        </div>
        <div class="marquee-tag">
          <Image
            src='/images/reel/2.jpeg'
            borderRadius='lg'
            height='16em'
            width='16em'
            objectFit='cover'
          />
        </div>
        <div class="marquee-tag">
          <Image
            src='/images/reel/3.jpeg'
            borderRadius='lg'
            height='16em'
            width='16em'
            objectFit='cover'
          />
        </div>
        <div class="marquee-tag">
          <Image
            src='/images/reel/4.jpeg'
            borderRadius='lg'
            height='16em'
            width='16em'
            objectFit='cover'
          />
        </div>
        <div class="marquee-tag">
          <Image
            src='/images/reel/5.jpeg'
            borderRadius='lg'
            height='16em'
            width='16em'
            objectFit='cover'
          />
        </div>
        <div class="marquee-tag">
          <Image
            src='/images/reel/6.jpeg'
            borderRadius='lg'
            height='16em'
            width='16em'
            objectFit='cover'
          />
        </div>
        <div class="marquee-tag">
          <Image
            src='/images/reel/7.jpeg'
            borderRadius='lg'
            height='16em'
            width='16em'
            objectFit='cover'
          />
        </div>
        <div class="marquee-tag">
          <Image
            src='/images/reel/8.jpeg'
            borderRadius='lg'
            height='16em'
            width='16em'
            objectFit='cover'
          />
        </div>
        <div class="marquee-tag">
          <Image
            src='/images/reel/9.jpeg'
            borderRadius='lg'
            height='16em'
            width='16em'
            objectFit='cover'
          />
        </div>
      </div>
    </div>
  </Container>
}

export default ScrollingImages;