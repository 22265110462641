import { Box, Button, Center, Checkbox, Container, Divider, Flex, Image, Input, Link, ListItem, UnorderedList, Radio, RadioGroup, Select, Stack, Text } from "@chakra-ui/react"
import { Field, Form, Formik } from 'formik'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react'

import React, { Component, useEffect } from "react"

export default class CollectRsvp extends Component {
  constructor(props){ 
    super(props)

    this.state = {
      setRsvpedCounts: false,
      guestNames: []
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    const setFieldValue = this.props.setFieldValue
    const formValues = this.props.values

    if (!this.state.setRsvpedCounts) {
      const vidhiRsvp = formValues.vidhiRsvp
      const welcomePartyRsvp = formValues.welcomePartyRsvp
      const weddingRsvp = formValues.weddingRsvp
      const receptionRsvp = formValues.receptionRsvp

      const guestDetails = JSON.parse(formValues.guestDetails)

      const guestNames = guestDetails['Website Names'].replace(' and ', '\,').split(',').map(s => s.trim()).filter(item => item)
      const vidhiRsvped = guestDetails["Vidhi RSVP Names"] && guestDetails["Vidhi RSVP Names"].trim()
        ? guestDetails["Vidhi RSVP Names"].split(',').map(s => s.trim())
        : []
      const welcomePartyRsvped = guestDetails["Welcome Party RSVP Names"] && guestDetails["Wedding RSVP Names"].trim()
        ? guestDetails["Welcome Party RSVP Names"].split(',').map(s => s.trim())
        : []
      const weddingRsvped = guestDetails["Wedding RSVP Names"] && guestDetails["Wedding RSVP Names"].trim()
        ? guestDetails["Wedding RSVP Names"].trim().split(',').map(s => s.trim())
        : []
      const receptionRsvped = guestDetails["Reception RSVP Names"] && guestDetails["Reception RSVP Names"].trim()
        ? guestDetails["Reception RSVP Names"].split(',').map(s => s.trim())
        : []

      if (!vidhiRsvp || !vidhiRsvp.length) {
        setFieldValue('vidhiRsvp', vidhiRsvped)
      }

      if (!welcomePartyRsvp || !welcomePartyRsvp.length) {
        setFieldValue('welcomePartyRsvp', welcomePartyRsvped)
      }

      if (!weddingRsvp || !weddingRsvp.length) {
        setFieldValue('weddingRsvp', weddingRsvped)
      }

      if (!receptionRsvp || !receptionRsvp.length) {
        setFieldValue('receptionRsvp', receptionRsvped)
      }

      this.setState({
        setRsvpedCounts: true,
        'guestNames': guestNames
      })
    }
  }

  componentDidUpdate() {
    const setFieldValue = this.props.setFieldValue
    const formValues = this.props.values

    if (!this.state.setRsvpedCounts) {
      const vidhiRsvp = formValues.vidhiRsvp
      const welcomePartyRsvp = formValues.welcomePartyRsvp
      const weddingRsvp = formValues.weddingRsvp
      const receptionRsvp = formValues.receptionRsvp

      const guestDetails = JSON.parse(formValues.guestDetails)

      const guestNames = guestDetails['Name'].replace(' and ', '\,').split(',').map(s => s.trim())
      const vidhiRsvped = guestDetails["Vidhi RSVP Names"] && guestDetails["Vidhi RSVP Names"].trim()
        ? guestDetails["Vidhi RSVP Names"].split(',').map(s => s.trim())
        : []
      const welcomePartyRsvped = guestDetails["Welcome Party RSVP Names"] && guestDetails["Welcome Party RSVP Names"].trim()
        ? guestDetails["Welcome Party RSVP Names"].split(',').map(s => s.trim())
        : []
      const weddingRsvped = guestDetails["Wedding RSVP Names"] && guestDetails["Wedding RSVP Names"].trim()
        ? guestDetails["Wedding RSVP Names"].split(',').map(s => s.trim())
        : []
      const receptionRsvped = guestDetails["Reception RSVP Names"] && guestDetails["Reception RSVP Names"].trim()
        ? guestDetails["Reception RSVP Names"].split(',').map(s => s.trim())
        : []

      if (!vidhiRsvp || !vidhiRsvp.length) {
        setFieldValue('vidhiRsvp', vidhiRsvped)
      }

      if (!welcomePartyRsvp || !welcomePartyRsvp.length) {
        setFieldValue('welcomePartyRsvp', welcomePartyRsvped)
      }

      if (!weddingRsvp || !weddingRsvp.length) {
        setFieldValue('weddingRsvp', weddingRsvped)
      }

      if (!receptionRsvp || !receptionRsvp.length) {
        setFieldValue('receptionRsvp', receptionRsvped)
      }

      this.setState({
        setRsvpedCounts: true,
        'guestNames': guestNames
      })
    }
  }

  render() {
    const values = this.props.values
    const guestDetails = JSON.parse(values.guestDetails)

    const guestNames = this.state.guestNames
    const vidhiInvited = guestDetails["Vidhi Invited"] > 0 ? parseInt(guestDetails["Vidhi Invited"]) : undefined
    const welcomePartyInvited = guestDetails["Welcome Party Invited"] > 0 ? parseInt(guestDetails["Welcome Party Invited"]) : undefined
    const weddingInvited = guestDetails["Wedding Invited"] > 0 ? parseInt(guestDetails["Wedding Invited"]) : undefined
    const receptionInvited = guestDetails["Reception Invited"] > 0 ? parseInt(guestDetails["Reception Invited"]) : undefined

    return (
      <Box className='rsvpCollection'>
        Please select who will be able to attend below. If no one is able to attend leave all checkboxes unselected and click "Confirm" at the bottom of the page.
        <br /><br />
        {vidhiInvited && 
          <FormControl className='rsvpSelect'>
            <div>
              <FormLabel>Manglik Prasango</FormLabel>
              <div className='eventDescription'>
                <Text>Date & Time: Friday, November 1, 2024 at 9:30 AM</Text>
                <Text>Venue: Knoxville Ranch & Event Center, West, TX</Text>
                <Text>Invited: {vidhiInvited} {vidhiInvited === 1 ? 'person' : 'people'}</Text>
              </div>
            </div>
            <div className='guestNames'>
              <Text>Select who will be attending:</Text>
              {guestNames.map((guestName) => {
                return <Field name='vidhiRsvp' type="checkbox">
                  {({ field, form }) => (
                    <Checkbox {...field} name='vidhiRsvp' isChecked={values.vidhiRsvp && values.vidhiRsvp.includes(guestName)} value={guestName} isDisabled={values.vidhiRsvp && values.vidhiRsvp.length >= vidhiInvited && !values.vidhiRsvp.includes(guestName)}>{guestName}</Checkbox>
                  )}
                </Field>
              })}
            </div>
          </FormControl>
        }
        {vidhiInvited && (welcomePartyInvited || weddingInvited || receptionInvited) && <Divider />}
        {welcomePartyInvited && 
          <FormControl className='rsvpSelect'>
            <FormLabel>Welcome Party</FormLabel>
            <div className='eventDescription'>
              <Text>Date & Time: Friday, November 1, 2024 at 6 PM</Text>
              <Text>Venue: Knoxville Ranch & Event Center, West, TX</Text>
              <Text>Invited: {welcomePartyInvited} {welcomePartyInvited === 1 ? 'person' : 'people'}</Text>
            </div>
            <div className='guestNames'>
              <Text>Select who will be attending:</Text>
              {guestNames.map((guestName) => {
                return <Field name='welcomePartyRsvp' type="checkbox">
                  {({ field, form }) => (
                    <Checkbox {...field} name='welcomePartyRsvp' isChecked={values.welcomePartyRsvp && values.welcomePartyRsvp.includes(guestName)} value={guestName} isDisabled={values.welcomePartyRsvp && values.welcomePartyRsvp.length >= welcomePartyInvited && !values.welcomePartyRsvp.includes(guestName)}>{guestName}</Checkbox>
                  )}
                </Field>
              })}
            </div>
          </FormControl>
        }
        {welcomePartyInvited && (weddingInvited || receptionInvited) && <Divider />}
        {weddingInvited && 
          <FormControl className='rsvpSelect'>
            <FormLabel>Wedding Ceremony</FormLabel>
            <div className='eventDescription'>
              <Text>Date & Time: Saturday, November 2, 2024 at 9 AM</Text>
              <Text>Venue: Knoxville Ranch & Event Center, West, TX</Text>
              <Text>Invited: {weddingInvited} {weddingInvited === 1 ? 'person' : 'people'}</Text>
            </div>
            <div className='guestNames'>
              <Text>Select who will be attending:</Text>
              {guestNames.map((guestName) => {
                return <Field name='weddingRsvp' type="checkbox">
                  {({ field, form }) => (
                    <Checkbox {...field} name='weddingRsvp' isChecked={values.weddingRsvp && values.weddingRsvp.includes(guestName)} value={guestName} isDisabled={values.weddingRsvp && values.weddingRsvp.length >= weddingInvited && !values.weddingRsvp.includes(guestName)}>{guestName}</Checkbox>
                  )}
                </Field>
              })}
            </div>
          </FormControl>
        }
        {weddingInvited && receptionInvited && <Divider />}
        {receptionInvited && 
          <FormControl className='rsvpSelect'>
            <FormLabel>Cocktail Hour & Reception</FormLabel>
            <div className='eventDescription'>
              <Text>Date & Time: Saturday, November 2, 2024 at 6 PM</Text>
              <Text>Venue: Knoxville Ranch & Event Center, West, TX</Text>
              <Text>Invited: {receptionInvited} {receptionInvited === 1 ? 'person' : 'people'}</Text>
            </div>
            <div className='guestNames'>
              <Text>Select who will be attending:</Text>
              {guestNames.map((guestName) => {
                return <Field name='receptionRsvp' type="checkbox">
                  {({ field, form }) => (
                    <Checkbox {...field} name='receptionRsvp' isChecked={values.receptionRsvp && values.receptionRsvp.includes(guestName)} value={guestName} isDisabled={values.receptionRsvp && values.receptionRsvp.length >= receptionInvited && !values.receptionRsvp.includes(guestName)}>{guestName}</Checkbox>
                  )}
                </Field>
              })}
            </div>
          </FormControl>
        }
      </Box>
    )
  }
}