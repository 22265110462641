import { AspectRatio, Button, Card, CardHeader, CardBody, CardFooter, Center, Container, Box, Divider, Flex, Grid, Heading, Image, SimpleGrid, Stack, StackDivider, Text } from "@chakra-ui/react"

import Person from './Person.js';


function WeddingParty() {
  return <Container maxW='container.xl' id="wedding-party">
    <Center>
      <Heading fontWeight='500' size='xl' display="flex" gap='.25em'>
        <span>The Bridesmaids <Image src='/images/emoji/1f451.png' display='inline-block' height='0.8em' verticalAlign='-0.05em' /></span>
      </Heading>
    </Center>
    <SimpleGrid className='weddingParty' spacing={{base: 6, sm: 6, md: 12}} columns={{base: 2, sm: 2, md: 4, lg: 4, xl: 5}} marginTop='2em' marginBottom='2em'>
      <Person
        imageUrl='/images/bridesmaids/aashish.jpeg'
        title='Bridesmaid'
        name='Aashish Patel'
      />
      <Person
        imageUrl='/images/bridesmaids/chanel.jpeg'
        title='Bridesmaid'
        name='Chanel Patel'
      />
      <Person
        imageUrl='/images/bridesmaids/marisol.jpeg'
        title='Bridesmaid'
        name='Marisol De La Cruz'
      />
      <Person
        imageUrl='/images/bridesmaids/neena.jpeg'
        title='Bridesmaid'
        name='Neena Naran'
      />
      <Person
        imageUrl='/images/bridesmaids/shalini.jpeg'
        title='Bridesmaid'
        name='Shalini Mehta'
      />
      <Person
        imageUrl='/images/bridesmaids/vimi.jpeg'
        title='Bridesmaid'
        name='Vimi Patel'
      />
    </SimpleGrid>
  </Container>;
}

export default WeddingParty;