import { Button, Card, CardHeader, CardBody, CardFooter, Center, Container, Box, Flex, Grid, Heading, Stack, StackDivider, Text } from "@chakra-ui/react"
import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";

function TimelineEvent({position, date, description}) {
  return (
    <Box>
      <Box height='5em' display='flex' alignItems='end' justifyContent='center'>
        {position === 'top' && (
          <Box paddingLeft='1em' paddingRight='1em'>
            <Text pt='2' fontSize='md' fontWeight='500' paddingBottom={'var(--chakra-space-1)'}>
              {description}
            </Text>
            <Text fontSize='sm' fontWeight='800' textTransform='uppercase'>
              {date}
            </Text>
          </Box>
        )}
      </Box>
      <div class="status" />
      <Box height='5em' display='flex' alignItems='start' justifyContent='center'>
        {position === 'bottom' && (
          <Box paddingLeft='1em' paddingRight='1em'>
            <Text fontSize='sm' fontWeight='800' textTransform='uppercase'>
              {date}
            </Text>
            <Text pt='2' fontSize='md' fontWeight='500' paddingTop={'var(--chakra-space-1)'}>
              {description}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default TimelineEvent;