import { Box, Button, Center, Container, Heading, Input, Link, Text } from "@chakra-ui/react";
import { ChevronLeftIcon } from '@chakra-ui/icons'
import React from "react";
import Confetti from 'react-confetti'

import { useHandleForm } from '../hooks/useHandleForm'

import RsvpForm from '../components/RsvpForm.js';

export default function Rsvp() {
  const {step, next, prev, goto} = useHandleForm(['0', '1', '2', '3', '4'])

  return <Box height='100%'>
    {step === 4 && <Confetti recycle={false} />}
    <Link href='/'>
    	<Text fontWeight='600' className='backHome'><ChevronLeftIcon />Back To Home</Text>
    </Link>
    <Container maxW='3xl' className='rsvpContainer'>
      <Box>
        <Center className='rsvpHeader' flexDirection='column'>
    	    <Heading fontWeight='500' size='2xl'>RSVP</Heading>
        </Center>
        <Container padding={0}>
          <RsvpForm
        		step={step}
            next={next} 
            prev={prev}
            goto={goto}
          />
        </Container>
      </Box>
    </Container>
  </Box>;
}
