import { Button, Card, CardHeader, CardBody, CardFooter, Center, Container, Box, Flex, Grid, Heading, Image, Stack, StackDivider, Text } from "@chakra-ui/react"
import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";

import TimelineEvent from './TimelineEvent.js';

function Timeline() {
  return <Container maxW='100%' paddingLeft={0} paddingRight={0} id="timeline">
    <Center>
      <Heading fontWeight='500' size='xl'>
        <span>A Brief History Of Love <Image display='inline-block' src='/images/emoji/2665.png' height='1em' verticalAlign='-0.2em' /></span>
      </Heading>
    </Center>
    <div>
      <Swiper
        navigation={true}
        modules={[Navigation]}
        watchSlidesProgress={true}
        slidesPerView={5}
        slidesPerGroup={5}
        className="timeline"
        breakpoints= {{
          320: {
            slidesPerView: 2,
            slidesPerGroup: 2
          },
          768: {
            slidesPerView: 3,
            slidesPerGroup: 3
          },
          1024: {
            slidesPerView: 4,
            slidesPerGroup: 4
          },
          1280:{
            slidesPerView: 5,
            slidesPerGroup: 5
          },
        }}>

        <SwiperSlide>
          <TimelineEvent
            position={'top'}
            date={'February 2023'}
            description={'We both swiped right on Dil Mil'} />
        </SwiperSlide>
        <SwiperSlide>
          <TimelineEvent
            position={'bottom'}
            date={'March 2023'}
            description={'Tejal made the first move'} />
        </SwiperSlide>
        <SwiperSlide>
          <TimelineEvent
            position={'top'}
            date={'April 2023'}
            description={'We met in Dallas and officially started dating'} />
        </SwiperSlide>
        <SwiperSlide>
          <TimelineEvent
            position={'bottom'}
            date={'May 2023'}
            description={'Anish met Tejal\'s parents'} />
        </SwiperSlide>
        <SwiperSlide>
          <TimelineEvent
            position={'top'}
            date={'September 2023'}
            description={'Our parents met each other'} />
        </SwiperSlide>
        <SwiperSlide>
          <TimelineEvent
            position={'bottom'}
            date={'November 2023'}
            description={'Anish proposed and we became coconut official'} />
        </SwiperSlide>
        <SwiperSlide>
          <TimelineEvent
            position={'top'}
            date={'November 2024'}
            description={'We are getting married!'} />
        </SwiperSlide>
      </Swiper>
    </div>
  </Container>
}

export default Timeline;