import { AspectRatio, Button, Card, CardHeader, CardBody, CardFooter, Center, Container, Box, Flex, Grid, Heading, Image, Stack, StackDivider, Text } from "@chakra-ui/react"

function Proposal() {
  return <Container maxW='container.xl' id="proposal">
    <Center flexDirection='column'>
      <Heading fontWeight='500' size='xl'>
        <span>The Proposal <Image src='/images/emoji/1f48e.png' display='inline-block' height='1.1em' verticalAlign='-0.2em' /></span>
      </Heading>
      <AspectRatio width={{base:'306px', sm:'500px', md:'750px'}} height={{base:'544px', sm:'450px', md:'450px'}} marginTop='2em'>
        <iframe
          title='naruto'
          src='https://www.youtube.com/embed/MOqEoKluJqs'
          allowFullScreen
        />
      </AspectRatio>
    </Center>
  </Container>;
}

export default Proposal;