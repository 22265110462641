import {  Box, Image, Text } from '@chakra-ui/react';

export default function Person({imageUrl, title, name}) {
  return (
    <Box position='relative' className='person'>
      <div className='gradient'>
        <Image
          src={imageUrl}
          borderRadius='lg'
          height='18em'
        />
      </div>
      <Box textAlign='center' position='absolute' bottom='2em' left='1em' right='1em' color='white'>
        <Text fontSize='sm' fontWeight='800' textTransform='uppercase'>
          {title}
        </Text>
        <Text pt='2' fontSize='md' fontWeight='500' paddingTop={'var(--chakra-space-1)'}>
          {name}
        </Text>
      </Box>
    </Box>
  );
}