import './styles/fonts.css'
import "swiper/css";
import "swiper/css/navigation";

import Main from './Main';

import theme from './theme';

import { ChakraProvider } from '@chakra-ui/react';
import * as React from 'react';

import { Analytics } from '@vercel/analytics/react';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Main />
      <Analytics />
    </ChakraProvider>
  );
}

export default App;
